import { Classes } from "@blueprintjs/core";
import CodeMirror from "codemirror";
import React, { useRef, memo, useEffect } from "react";
import styled from "styled-components";
import { EditorStyles } from "components/app/CodeEditor/editorStyles";
import { disableNudgeAction } from "legacy/actions/widgetActions";
import {
  LINTING_ENABLED_CODE_WIDGET_MODES,
  WIDGET_PADDING,
} from "legacy/constants/WidgetConstants";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { useAppDispatch } from "store/helpers";
import { getComponentDimensions } from "utils/size";
import { useStyleClassNames, useTypographyStyling } from "../typographyHooks";
import { DEFAULT_CODE_WIDGET_LABEL_TEXT_STYLE_VARIANT } from "./constants";
import { CodeComponentWithManagedLayoutProps } from "./types";
import "./jsonLint";

import "codemirror/mode/javascript/javascript";
import "codemirror/mode/ruby/ruby";
import "codemirror/mode/htmlembedded/htmlembedded";
import "codemirror/mode/markdown/markdown";
import "codemirror/mode/python/python";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/comment/comment";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/display/placeholder";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/indent-fold";
import "codemirror/addon/lint/lint";
import "components/app/CodeEditor/CodeEditor.css";

CodeMirror.defineMIME("application/hjson", {
  name: "javascript",
  json: true,
});

interface Props {
  initialValue: string;
  // Current value of the meta prop
  stringValue?: string;
  onChange: (value: string) => void;
  mode: string;
  isEditable: boolean;
  isValid: boolean;
  lineWrapping: boolean;
  lintingEnabled: boolean;
  newlineCharacter?: string;
  height?: number;
  placeholder?: string;
  showLineNumbers?: boolean;
}

const StyledWrapper = styled.div<{
  height?: number;
}>`
  ${EditorStyles}
  height: ${(props) => (props.height ? `${props.height}px` : "fit-content")};

  overflow: hidden;

  && {
    .CodeMirror,
    .CodeMirror-scroll,
    .CodeMirror-gutter {
      height: "auto";
    }
  }
`;

const defaultOptions: CodeMirror.EditorConfiguration = {
  viewportMargin: 10,
  tabSize: 2,
  lineWrapping: false,
  lint: true,
  scrollbarStyle: "native",
  extraKeys: {
    "Ctrl-/": (cm) => cm.execCommand("toggleComment"),
    "Cmd-/": (cm) => cm.execCommand("toggleComment"),
    "Ctrl-F": (cm) => cm.execCommand("findPersistent"),
    "Cmd-F": (cm) => cm.execCommand("findPersistent"),
  },
  theme: "neo",
  foldOptions: {},
  foldGutter: true,
  gutters: [
    "CodeMirror-lint-markers",
    "CodeMirror-foldgutter",
    "CodeMirror-linenumbers",
  ],
};

const defaultNewline = (props: Props) => {
  const rn = props.initialValue?.indexOf("\r\n");
  const n = props.initialValue?.indexOf("\n");
  if (rn === -1 && n === -1) return "\n";
  if (rn === -1) return "\n";
  if (n === -1) return "\r\n";
  return rn < n ? "\r\n" : "\n";
};

const updateNewlineCharacter = (props: Props, editor: CodeMirror.Editor) => {
  const newlineCharacter =
    props.newlineCharacter !== undefined
      ? props.newlineCharacter
      : defaultNewline(props);

  if (newlineCharacter !== editor.getOption("lineSeparator")) {
    editor.setOption("lineSeparator", newlineCharacter);
    if (props.initialValue) {
      editor.setValue(props.initialValue);
    }
  }
};

function reinitializeCodeMirror(editor: CodeMirror.Editor, props: Props) {
  if (props.placeholder !== editor.getOption("placeholder")) {
    editor.setOption("placeholder", props.placeholder);
  }
  const readOnly = !props.isEditable;
  if (readOnly !== editor.getOption("readOnly")) {
    editor.setOption("readOnly", readOnly);
    // hiding cursor, instead of using "nocursor" mode, to allow copying and proper scrolling
    editor.setOption("cursorBlinkRate", readOnly ? -1 : 530);
  }
  if (props.mode !== editor.getOption("mode")) {
    editor.setOption("mode", props.mode);
    editor.performLint();
  }
  if (props.lineWrapping !== editor.getOption("lineWrapping")) {
    editor.setOption("lineWrapping", props.lineWrapping);
  }

  if (props.showLineNumbers !== editor.getOption("lineNumbers")) {
    editor.setOption("lineNumbers", props.showLineNumbers);
  }

  updateNewlineCharacter(props, editor);
}

function CodeEditor(props: Props) {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const cm = useRef<CodeMirror.Editor | null>(null);
  const editor = cm.current;
  const dispatch = useAppDispatch();

  if (editor) {
    reinitializeCodeMirror(editor, props);
  }

  const { stringValue } = props;

  useEffect(() => {
    // Watching for changes in meta properties from an external source,
    // such as calling resetComponent.
    if (
      cm.current &&
      typeof stringValue === "string" &&
      cm.current.getValue() !== stringValue
    ) {
      cm.current.setValue(stringValue);
      cm.current.performLint();
    }
  }, [stringValue]);

  // refresh styles in the case that the editor is inside a modal
  useEffect(() => {
    const timer = setTimeout(() => {
      cm.current?.refresh();
    }, 1200);

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <StyledWrapper
      height={props.height}
      className={`${CLASS_NAMES.CODE_EDITOR}
      ${props.isValid ? "" : CLASS_NAMES.ERROR_MODIFIER}
      ${props.isEditable ? "" : CLASS_NAMES.DISABLED_MODIFIER}`}
      data-test="ce-text-area-wrapper"
    >
      <textarea
        ref={(v) => {
          if (!textareaRef.current && v) {
            textareaRef.current = v;
            cm.current = CodeMirror.fromTextArea(v, {
              ...defaultOptions,
              lint: props.lintingEnabled,
              lineNumbers: props.showLineNumbers,
            });
            reinitializeCodeMirror(cm.current, props);
            cm.current.on("change", (e, changeObj) => {
              // ignore changes that happened programmatically with setValue
              if (changeObj.origin !== "setValue") {
                props.onChange(e.getValue(props.newlineCharacter));
              }
            });
            cm.current.on("focus", (e) => {
              dispatch(disableNudgeAction(true));
              // in the edge case that the styling is off for some reason
              // refresh on focus to fix it
              cm.current?.refresh();
            });
            cm.current.on("blur", (e) => {
              dispatch(disableNudgeAction(false));
            });
            cm.current.setSize(null, "100%");
          }
        }}
      />
    </StyledWrapper>
  );
}

const MemoCodeEditor = memo(CodeEditor);

const StyledOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  label {
    flex: 0 0 auto;
    text-align: left;
    margin: 8px ${WIDGET_PADDING * 2}px 8px 0;
  }
`;

export const CodeComponentWithManagedLayout: React.FC<
  CodeComponentWithManagedLayoutProps
> = (props) => {
  const { label } = props;
  const labelHeight = label ? 40 : 0;
  const mainHeight =
    props.height.mode === "fitContent"
      ? undefined
      : getComponentDimensions(props).componentHeight - labelHeight;

  // Set as true for code editors created before this property was introduced
  const showLineNumbers = props.showLineNumbers !== false;

  const labelProps = useTypographyStyling({
    textStyle: props.labelProps?.textStyle,
    defaultTextStyleVariant: DEFAULT_CODE_WIDGET_LABEL_TEXT_STYLE_VARIANT,
  });

  const labelClass = useStyleClassNames({
    textStyleVariant: labelProps.textStyleVariant,
    type: "label",
  });

  return (
    <StyledOuterWrapper
      key={props.mode}
      className={props.isLoading ? Classes.SKELETON : ""}
    >
      {!props.isLoading && label && (
        <label className={labelClass} style={labelProps?.style}>
          {props.isRequired && props.label.indexOf("*") === -1 && (
            <span className={`asterisk ${CLASS_NAMES.ERROR_MODIFIER}`}>* </span>
          )}
          {label}
        </label>
      )}
      <MemoCodeEditor
        height={mainHeight}
        mode={props.mode}
        isEditable={!props.readOnly}
        isValid={props.isValid}
        initialValue={props.initialValue}
        onChange={props.onChange}
        stringValue={props.stringValue}
        lineWrapping={props.lineWrapping}
        showLineNumbers={showLineNumbers}
        newlineCharacter={props.newlineCharacter}
        lintingEnabled={
          LINTING_ENABLED_CODE_WIDGET_MODES.indexOf(props.mode) !== -1
        }
      />
    </StyledOuterWrapper>
  );
};
