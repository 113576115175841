import Decimal from "decimal.js";
import logger from "utils/logger";

export const sanitizeNumericInputValue = (
  value: number | string,
  isPercentInput: boolean,
): string => {
  const sanitizedValue =
    typeof value === "string" ? value.trim() : String(value).trim();

  try {
    const isValidDecimal = /^[+-]?\d*\.?\d+(e[+-]?\d+)?$/i.test(sanitizedValue);

    const number = String(
      isPercentInput && sanitizedValue !== "" && isValidDecimal
        ? new Decimal(sanitizedValue).times(100)
        : sanitizedValue,
    );
    return number;
  } catch (error) {
    logger.error(
      `Decimal creation failed for value: ${sanitizedValue}, error: ${error}`,
    );
    return "";
  }
};
