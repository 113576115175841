import { Padding } from "@superblocksteam/shared";
import React, { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import DynamicSVG from "components/ui/DynamicSVG";
import { selectWidgets } from "legacy/actions/widgetActions";
import { WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import PaddingOverlay from "legacy/pages/Editor/CanvasArenas/PaddingOverlay";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import {
  getCurrentPageUrlState,
  getRoutes,
} from "legacy/selectors/routeSelectors";
import {
  selectGeneratedTheme,
  selectGeneratedThemeTypographies,
} from "legacy/selectors/themeSelectors";
import { getSystemQueryParams } from "legacy/utils/queryParams";
import { useAppSelector } from "store/helpers";
import { NavigationEvent } from "utils/polyfills/interceptNavigation";
import { getComponentDimensions } from "utils/size";
import { ButtonLikeLink } from "../Shared/Button";
import { isFitContent } from "../base/sizing";
import {
  getApplicableMaxWidth,
  getApplicableMinWidth,
} from "../base/sizing/canvasWidthUtil";
import { useTypographyStyling } from "../typographyHooks";
import {
  getFontSizeInPxFromTextStyle,
  getIconSizeBasedOnFontSize,
} from "../typographyUtils";
import {
  LinkComponentProps,
  LinkComponentWithLayoutManagedProps,
} from "./types";
import {
  backgroundColorDefaultValue,
  borderDefaultValue,
  borderRadiusDefaultValue,
  getLinkProperties,
  paddingDefaultValue,
  textAlignmentDefaultValue,
  textColorDefaultValue,
  textVariantDefaultValue,
} from "./utils";

const LinkComponent = memo((props: LinkComponentProps) => {
  const { keepExistingQueryParams, openInNewTab, onLinkClick, widgetId } =
    props;
  const dispatch = useDispatch();
  const currentPageUrlState = useAppSelector(getCurrentPageUrlState);
  const systemQueryParams = useAppSelector(getSystemQueryParams);
  const appMode = useAppSelector(getAppMode);
  const routes = useAppSelector(getRoutes);
  const { url, urlForInternalNavigation } = getLinkProperties(
    {
      url: props.url,
      linkTo: props.linkTo,
      keepExistingQueryParams: keepExistingQueryParams,
      targetApp: props.targetApp,
      queryParams: props.queryParams,
      routeParams: props.routeParams,
      routePathDescriptor: props.routePathDescriptor,
      routeId: props.routeId,
    },
    {
      routes,
      currentPageUrlState,
      systemQueryParams,
      appMode,
    },
  );

  const useInternalNavigation =
    !props.isDisabled && !openInNewTab && urlForInternalNavigation;

  const onClick = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const forceNewTab = e.ctrlKey || e.metaKey;

      if (!forceNewTab && useInternalNavigation && "navigation" in window) {
        e.preventDefault();
        (window.navigation as any).dispatchEvent(
          new NavigationEvent(
            true,
            urlForInternalNavigation,
            undefined,
            false,
            false,
          ),
        );
      } else if (appMode === APP_MODE.EDIT && widgetId) {
        dispatch(selectWidgets([widgetId], false));
      }

      if (onLinkClick) {
        onLinkClick(e);
      }
    },
    [
      useInternalNavigation,
      appMode,
      widgetId,
      onLinkClick,
      urlForInternalNavigation,
      dispatch,
    ],
  );

  return (
    <div
      data-dd-action-name={props.ddogActionName ?? "link"}
      style={{
        position: "relative",
        userSelect: appMode === APP_MODE.EDIT ? "none" : "auto",
      }}
    >
      <ButtonLikeLink
        {...props}
        url={url}
        linkStyle={props.linkStyle}
        disabled={props.isDisabled}
        useDynamicContrast={true}
        onClick={onClick}
      />
      {appMode ? (
        <PaddingOverlay
          widgetId={props.widgetId ?? ""}
          padding={props.padding}
          appMode={appMode}
        />
      ) : null}
    </div>
  );
});

LinkComponent.displayName = "Link";

const clamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);

export const LinkComponentWithLayoutManaged = (
  props: LinkComponentWithLayoutManagedProps,
) => {
  const theme = useSelector(selectGeneratedTheme);
  const themeTypographies = useAppSelector(selectGeneratedThemeTypographies);
  const padding = props.padding ?? paddingDefaultValue(props);
  const defaultTextStyleVariant = textVariantDefaultValue(props);
  const defaultBorder = borderDefaultValue(props, theme);
  const defaultBorderRadius = borderRadiusDefaultValue(props, theme);
  const defaultBackgroundColor = backgroundColorDefaultValue(props, theme);
  let { backgroundColor, border, borderRadius } = props;

  if (props.linkStyle === "LINK") {
    // Force default values for button-only styles
    backgroundColor = undefined;
    border = undefined;
    borderRadius = undefined;
  }

  const textProps = useTypographyStyling({
    textStyle: props.textProps?.textStyle,
    defaultTextStyleVariant,
  });

  const textColor =
    textProps?.style?.color ?? textColorDefaultValue(props, theme);

  const { componentWidth } = getComponentDimensions(props);
  const isAutoWidth = isFitContent(props.width.mode);
  const isAutoHeight = isFitContent(props.height.mode);

  let maxWidth = (getApplicableMaxWidth(props) ?? props.internalMaxWidth)
    ?.value;
  if (maxWidth) {
    maxWidth = maxWidth - 2 * WIDGET_PADDING;
  }
  let minWidth = getApplicableMinWidth(props)?.value;
  if (minWidth) {
    minWidth = minWidth - 2 * WIDGET_PADDING;
  }

  const iconSize = useMemo(() => {
    return textProps.textStyleVariant
      ? getIconSizeBasedOnFontSize(
          getFontSizeInPxFromTextStyle({
            nestedProps: props.textProps?.textStyle,
            typographies: themeTypographies,
            textStyleVariant: textProps.textStyleVariant,
            defaultTextStyleVariant,
          }),
        )
      : clamp(
          !isAutoWidth
            ? componentWidth - Padding.x(padding).value - WIDGET_PADDING * 2
            : 18,
          8,
          18,
        );
  }, [
    textProps.textStyleVariant,
    props.textProps?.textStyle,
    themeTypographies,
    defaultTextStyleVariant,
    isAutoWidth,
    componentWidth,
    padding,
  ]);

  const icon = useMemo(() => {
    return props.icon ? (
      <DynamicSVG size={iconSize} iconName={props.icon} />
    ) : null;
  }, [iconSize, props.icon]);

  return (
    <LinkComponent
      {...props}
      key={props.widgetId}
      width={isAutoWidth ? "auto" : "100%"}
      height={isAutoHeight ? "auto" : "100%"}
      maxWidth={maxWidth}
      minWidth={minWidth}
      iconPosition={props.iconPosition ?? "LEFT"}
      ddogActionName="link-widget"
      textAlignment={props.textAlignment ?? textAlignmentDefaultValue(props)}
      padding={padding}
      textProps={textProps}
      icon={icon}
      textColor={textColor}
      backgroundColor={backgroundColor ?? defaultBackgroundColor}
      border={border ?? defaultBorder}
      borderRadius={borderRadius ?? defaultBorderRadius}
      url={props.url}
    />
  );
};
