import { Dimension } from "@superblocksteam/shared";
import { Dashboard as DashboardComponent } from "@uppy/react";
import { Tooltip } from "antd";
import { debounce, isEmpty } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { GridDefaults, WIDGET_PADDING } from "legacy/constants/WidgetConstants";
import { GeneratedTheme } from "legacy/themes";
import logger from "utils/logger";
import { getComponentDimensions } from "utils/size";

import { WidgetPropsRuntime } from "../BaseWidget";
import {
  getApplicableMaxHeight,
  getApplicableMinHeight,
  isFitContent,
} from "../base/sizing";
import { WithMeta } from "../withMeta";
import { SBUppy } from "./FilePickerSingleton";

interface FilePickerComponentProps extends WidgetPropsRuntime, WithMeta {
  selectionType?: number;
  files?: any[];
  maxFileSize?: number;
  allowedFileTypes: string[];
  isRequired?: boolean;
  isLoading: boolean;
  generatedTheme: GeneratedTheme;
  uppy: SBUppy;
  isValid?: boolean;
}

const Wrapper = styled.div`
  .uppy-Dashboard-inner {
    border: 1px solid transparent;
  }
  &[data-is-invalid="true"] .uppy-Dashboard-inner {
    border: 1px solid
      ${(props: { $errorBorderColor: string }) => props.$errorBorderColor};
  }
`;

const EMPTY_BODY_HEIGHT = 61;
const HEADER_HEIGHT = 50;
const PADDING_EXTRA = 20;

export const FilepickerComponent = (props: FilePickerComponentProps) => {
  const { allowedFileTypes } = props;
  const note: string | undefined = useMemo(() => {
    let str = "";
    if (
      allowedFileTypes &&
      Array.isArray(allowedFileTypes) &&
      !allowedFileTypes.includes("*") &&
      !isEmpty(allowedFileTypes)
    ) {
      str = `Allowed types: ${allowedFileTypes.join(", ")}`;
    } else if (allowedFileTypes && !Array.isArray(allowedFileTypes)) {
      logger.warn(
        `allowedFileTypes expected to be an an array. Type: ${typeof allowedFileTypes} value: ${JSON.stringify(
          allowedFileTypes,
          null,
          2,
        )}`,
      );
    }

    if (str) return str;

    return;
  }, [allowedFileTypes]);

  const maxHeight = getApplicableMaxHeight(props);
  const minHeight = getApplicableMinHeight(props);
  const isAutoHeight = isFitContent(props.height.mode);
  const { componentHeight } = getComponentDimensions(props);

  const minHeightPx = minHeight
    ? Dimension.toPx(minHeight, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value
    : undefined;
  const maxHeightPx = maxHeight
    ? Dimension.toPx(maxHeight, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value
    : undefined;
  const [filepickerHeight, setFilepickerHeight] = useState<number | undefined>(
    undefined,
  );

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const getFilepickerFilesSectionDiv = useCallback(() => {
    const header = wrapperRef.current?.querySelector(
      ".uppy-DashboardContent-bar",
    ) as HTMLDivElement | undefined;

    const body = wrapperRef.current?.querySelector(".uppy-Dashboard-files")
      ?.firstChild?.firstChild as HTMLDivElement | undefined;
    return body && header
      ? body.offsetHeight + header.offsetHeight + PADDING_EXTRA
      : undefined;
  }, []);

  const calculateFilepickerHeight = useCallback(() => {
    if (!isAutoHeight) return;
    const divHeight = getFilepickerFilesSectionDiv();

    let height = divHeight
      ? divHeight
      : EMPTY_BODY_HEIGHT + HEADER_HEIGHT + PADDING_EXTRA;
    if (maxHeightPx != null && height > maxHeightPx) {
      height = maxHeightPx - 2 * WIDGET_PADDING;
    }
    if (minHeightPx != null && height < minHeightPx) {
      height = minHeightPx - 2 * WIDGET_PADDING;
    }

    setFilepickerHeight(height);
  }, [getFilepickerFilesSectionDiv, isAutoHeight, maxHeightPx, minHeightPx]);

  // add a resize observer to the filepicker to update the height when the content changes
  useEffect(() => {
    // Run on mount and adjust on nav resize
    calculateFilepickerHeight();
    const debouncedHeightCalculations = debounce(calculateFilepickerHeight, 1);
    const resizeObserver = new ResizeObserver(() => {
      debouncedHeightCalculations();
    });
    const mutationObserver = new MutationObserver(() => {
      debouncedHeightCalculations();
    });

    const wrapper = wrapperRef.current;
    if (wrapper) {
      mutationObserver.observe(wrapper, {
        childList: true,
        subtree: true,
      });
      resizeObserver.observe(wrapper);
    }

    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
    };
  }, [calculateFilepickerHeight, props.widgetId, getFilepickerFilesSectionDiv]);

  const height = isAutoHeight ? filepickerHeight : componentHeight;
  return (
    <Tooltip
      title={props.isValid ? undefined : "This field is required"}
      overlayClassName="error-tooltip"
      placement="bottom"
    >
      <Wrapper
        ref={wrapperRef}
        id={`filepicker-component-${props.widgetId}`}
        data-is-invalid={!props.isValid}
        $errorBorderColor={props.generatedTheme.colors.danger}
      >
        <DashboardComponent
          uppy={props.uppy}
          // This component doesn't re-render correctly, so we are forcing it to re-render when
          // we need it to. If we add more properties, they need to be part of the key.
          key={`${props.widgetId}-${height}-${props.selectionType}-${note}-${props.generatedTheme.mode}-${props.isDisabled}`}
          width={"100%"}
          height={height}
          proudlyDisplayPoweredByUppy={false}
          hideUploadButton={true}
          note={note}
          style={{ minHeight: minHeightPx, maxHeight: maxHeightPx }}
          theme={props.generatedTheme.mode === "DARK" ? "dark" : "light"}
          disabled={props.isDisabled}
        />
      </Wrapper>
    </Tooltip>
  );
};
